<template>
  <b-card>
    <b-row>
      <b-col class="2">
        <img 
          :src="require('@/assets/images/avatars/logo_Avatar.png')" 
          width="104"
          height="104"
          class="img-fluid rounded mr-1" 
        >
      </b-col>
      <b-col cols="10">
        <base-info
          :geturl="dataurl"
          :data-id="$route.params.vehicle_id"
          :fields="fieldInfo"
          :col_single="false"
          :isedit="false"
          :isdelete="false"
        />
      </b-col>
      <b-col cols="12">
        <hr style="margin-bottom:0px;margin-top:25px;">
      </b-col>
      <basetable
        ref="basetable"
        class="col-md-12"
        :dataurl="dataurl+'/'+$route.params.vehicle_id+'/maintenance?filter[status]=done'"
        :fields="fields"
        :baseroute="baseroute"
        :is-edit="false"
        :is-delete="false"
        :is-add="false"
        :is-filter="false"
      >
        <template #cell(_)="">
        <!-- <b-button
          size="sm"
          variant="primary"
          class="mr-1 btn-icon"
          @click="viewForm(data)"
        >
          <feather-icon icon="FolderIcon" />
        </b-button> -->
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'request'"
            class="badge bg-info"
          >
            Pengajuan
          </span>
          <span
            v-if="data.item.status == 'plan'"
            class="badge bg-info"
          >
            Rencana
          </span>
          <span
            v-if="data.item.status == 'realisation'"
            class="badge bg-success"
          >
            Perawatan
          </span>
          <span
            v-if="data.item.status == 'reject'"
            class="badge bg-danger"
          >
            Ditolak
          </span>
          <span
            v-if="data.item.status == 'done'"
            class="badge bg-success"
          >
            Selesai
          </span>
        </template>
      </basetable>
    </b-row>
  </b-card>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BRow,BCol, BButton, BTable, BOverlay, BAvatar } from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    basetable,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BOverlay,
    BAvatar,
    BaseInfo
  },
  props:{
    status:{
      type:String,
      default:'all'
    }
  },
  data() {
    return {
      dataurl:"/vehicle",
      baseroute:"perawatan",
      fields: [
        { key: 'name', label: 'Nama Perawatan', sortable: true },
        { key: 'description', label: 'Deskripsi', sortable: true },
        { key: 'realisation_km', label: 'KM Realisasi', sortable: true },
        { key: 'realisation_cost', label: 'Realisasi Biaya', sortable: true },
        { key: 'realisation_date', label: 'Tanggal Realisasi', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      fieldInfo: [
        { key: 'number', label: 'Nomor Polisi'},
        { key: 'variant.model', label: 'Model'},
        { key: 'year', label: 'Tahun Pembuatan'},
        { key: 'branch.name', label: 'Cabang'},
      ],
    }
  },
  mounted(){
    // if(this.status != 'all')
    //   this.show()
    // alert(JSON.stringify(this.$route.params))
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
  }
}
</script>
<style lang="">

</style>
